<template>
    <Modal>
        <template v-if="isLoading">
            <h1>
                <Placeholder :width="70"/>
            </h1>
            <Placeholder element="time" :width="35"/>
            <p>
                <Placeholder :width="80"/>
            </p>
            <Placeholder element="div" :height="51" :border-radius="15" style="margin: 2.2rem 0;"/>
            <p>
                <small>Betalt</small><br>
                <strong class="larger">
                    <Placeholder :width="30"/>
                </strong>
            </p>
        </template>
        <template v-else>
            <h1>{{ expense.description }}</h1>
            <time>{{ formatDateToFnsDate(expense.created.toDate()) }}</time>
            <p v-if="expense.description ">{{ expense.description }}</p>
            <ViewFile v-if="expense.file" v-bind="expense.file"/>
            <p>
                <small>Betalt</small><br>
                <strong class="larger">{{ formatNumberToPrice(expense.price) }}</strong>
            </p>
        </template>

        <template #footer>
            <ActionButton label="Slet" icon="trash" :small="true" :secondary="true" :red="true"
                          @click="isConfirmRemoveDialogOpen = true"/>
        </template>

        <Dialog v-if="isConfirmRemoveDialogOpen">
            <template #title>Slet udgift</template>
            <template #default>
                <p>Du er ved at slette en udgft. Når først en ugift er slettet, kan den ikke genskabes og bilaget kan
                    ikke findes frem igen.</p>
                <p>Er du sikker på at vil slette den alligevel?</p>
            </template>
            <template #actions>
                <ActionButton icon="trash" label="Slet alligevel" :small="true" :red="true" @click="remove"/>
                <ActionButton icon="signout" label="Fortryd" :small="true" :secondary="true"
                              @click="isConfirmRemoveDialogOpen = false"/>
            </template>
        </Dialog>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import { reactive, toRefs } from 'vue';
import { expensesCollection } from '@/utils/collections';
import Placeholder from '@/components/Placeholder.vue';
import methods from '@/utils/methods';
import ViewFile from '@/components/ViewFile.vue';
import useState from '@/store';
import { useRoute } from 'vue-router';
import Dialog from '@/components/Dialog.vue';

export default {
	name: 'Expense',
	components: {
		Modal,
		ActionButton,
		Placeholder,
		ViewFile,
		Dialog,
	},
	mixins: [methods],
	setup() {
		const { modalData } = useState();
		const route = useRoute();
		const data = reactive({
			budget: route.params.budget,
			expense: {
				id: route.params.id,
				...modalData.value,
			},
			isLoading: true,
			isConfirmRemoveDialogOpen: false,
		});

		if (data.expense.description) {
			data.isLoading = false;
		} else {
			expensesCollection(data.budget).doc(data.expense.id).get().then((snapshot) => {
				data.expense = { id: snapshot.id, ...snapshot.data() };
				data.isLoading = false;
			});
		}

		return { ...toRefs(data) };
	},
	methods: {
		async remove() {
			try {
				await expensesCollection(this.budget).doc(this.expense.id).delete();
				await this.$router.push({ name: 'view-budget', params: { id: this.budget } });
			} catch (err) {
				console.log(err);
			}
		},
	},
};
</script>

<style lang="less" scoped>
h1 {
    margin-bottom: 0 !important;
}
</style>
